@import 'tokens'

// SCREENS
$xl-desktop: 1500px
$desktop: 1320px
$desktop-mini: 1260px
$lg-tablet: 1199px
$xl-tablet: 1024px
$tablet: 992px
$tablet-mini: 768px
$mobail: 750px
$pre-mobail: 600px
$sm-mobail: 575px


$max-width-nopadding: 1400px
$max-width: 1560px
$min-width: 300px


// осовные шрифты
// ==============
$font: var(--font-proximaNova), sans-serif
$fontRaleway: 'Raleway', sans-serif
