@charset "UTF-8"

@import "modules/variables"
@import "modules/media"


// основыне цвета
// ==============
$color: #2a2d33 // base
$color-skills-team: #1F2E3F
$main-color: #7927E0


// цвета состояний ссылок
// ======================
$link: $main-color // a:normal
$link-hover: #9D5DED // a:hover
$hover: $main-color

$link-blue: #419CF0


// Слайдер на главной
// ======================
$active-slider: $main-color
$grey-slider: #d4dae5
$dark-grey-slider: #30343e


// кнопки
// ======================
$btn-link: $main-color // a:normal
$btn-hover: $main-color // a:hover
$slider-button-color: #e4e4e4

$payment-back: #D4C0ED


// вспомогательные цвета
// =====================
$grey: #b5bbc7
$grey-color: #CFD2D6
$grey-border: #e0e2e5
$border: #f0f0f0
$red: #f34040
$white: #ffffff
$black: #000000
$lightbg: #f6f8f9
$lightgrey: #979797
$blue: #3A74B7
$yellow: #ffdeB8
$quotebg: #f9f7ef
$bitrix: #3bc8f5
$bitrix-hover: #68ddff
$promomed-color: #2C42B6
$slider-color: #bdc2cd
$purple-light-bg: #E7D3FF
$dark-grey: #597289
$color-matrix: #2E2E27
$color-matrix-light: #727E8C
$color-matrix-green: #70CF00
// цвета фонов
// ===========
$bg: #f5f7fa // фон
$light-gray: #f1f5f9 //новый цвет фона в блоках в кейсе aimed
$light-purple: #e7d3ff //новый цвет фона в блоках текста в кейсе aimed
$light-blue: #f6f8f9
$light-brown: #D7D8DE //новый цвет фона в блоках в кейсе rgs
$light-red:#A91631

// цвета ошибки
// ===========
$error: #f77 // ошибка


@mixin h2()
    margin-bottom: 18px
    font: 700 60px/76px $font
    +max-width($desktop)
        font-size: 45px
    +max-width($mobail)
        margin-bottom: 12px
        font: 700 24px/32px $font


@mixin padding()
    padding: 0 80px
    +max-width($tablet)
        padding: 0 40px
    +max-width($mobail)
        padding: 0 15px

@mixin full-wrapper()
    margin: 0 auto
    max-width: $max-width
    min-width: $min-width
    +padding