@import "variables"

@mixin max-content()
    max-width: max-content
    width: 100%


@mixin width($width)
    max-width: $width
    width: $width

@mixin max-width($width)
    @media only screen and (max-width: $width)
        @content

@mixin min-width($width)
    @media only screen and (min-width: $width)
        @content


@mixin max-height($height)
    @media only screen and (max-height: $height)
        @content

@mixin min-height($height)
    @media only screen and (min-height: $height)
        @content

@mixin padding()
    padding: 0 80px
    +max-width($tablet)
        padding: 0 40px
    +max-width($mobail)
        padding: 0 15px


@mixin bottom-padding($d, $t, $m)
    padding-bottom: #{$d}px
    +max-width($tablet)
        padding-bottom: #{$t}px
    +max-width($mobail)
        padding-bottom: #{$m}px


@mixin full-wrapper()
    margin: 0 auto
    max-width: $max-width
    min-width: $min-width
    +padding