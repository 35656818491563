@import "../config"
@import "/styles/config"

.nav
    +full-wrapper
    margin: 20px auto 36px
    font: 400 18px/22.5px $font
    color: #9CA6B9
    +max-width($mobail)
        display: none
        font: 400 14px/17.5px $font

    &__link
        color: #9CA6B9
        transition: color 0.3s ease

        &:hover
            color: $main-color


    &__marketing
        margin-top: 60px
        margin-bottom: 0


    &--cases
        font: 400 14px/143% $font
        color: $color
        padding: 4px 8px
        background: rgba(255, 255, 255, 0.6)
        width: fit-content
        min-width: auto
        margin: 0
        +max-width($tablet)
            padding: 2px 4px

        .nav__link
            color: $color
            transition: color 0.3s ease

            &:hover
                color: $main-color