@import "../config"
@import "/styles/config"


.case
    *
        margin: 0
        padding: 0
        box-sizing: border-box


    &__item
        margin: 0 0 100px 0
        +max-width($tablet)
            margin: 0 0 40px 0

        &--is-marginless
            margin: 0

        &--small-paddings
            padding: 60px 0 80px 0
            +max-width($mobail)
                padding: 32px 0 40px 0

        &--big-paddings
            padding: 100px 0
            +max-width($tablet)
                padding: 60px 0
            +max-width($mobail)
                padding: 40px 0


.full-wrapper
    width: 100%
    margin: 0 auto
    max-width: $max-width
    min-width: $min-width
    +padding